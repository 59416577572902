import React, { useContext, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import Fade from 'react-awesome-reveal';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import CarouselComp from '../Carousel/Carousel';

const Experience = () => {

  const { experience } = useContext(PortfolioContext);
  const { resume } = experience;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="experience">
      <Container>
        <div className="project-wrapper">
          <Title title="Experience" />

          {experience.map((experiences) => {
            const {
              id,
              title,
              info,
              infoExtended,
              info2,
              url,
              repo,
              img,
              alt,
              img2,
              alt2,
              img3,
              alt3,
              img4,
              alt4,
              img5,
              alt5,
              img6,
              alt6,
              img7,
              alt7,
              img8,
              alt8,
            } = experiences;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={700}
                    distance="30px"
                    triggerOnce={true}
                  >
                    <div className="project-wrapper__text">
                    
                      {title === 'WordPress' ? (
                        <h3 className="project-wrapper__text-title">
                          WordPress&nbsp;&nbsp;
                          <img
                            src="/wordpress.svg"
                            alt="WordPress"
                            title="WordPress"
                            width="30"
                            height="30"
                            className="rounded stack-icons"
                          />
                        </h3>
                      ) : (
                          title === 'Richie Billions' ? (
                            <h3 className="project-wrapper__text-title" id="richiebillions">
                              Richie Billions 💲
                            </h3>
                          ) : (
                            <h3 className="project-wrapper__text-title" id="test1">{title || ''}</h3>
                          )
                      )}

                      <div>
                        <p>
                          {<span dangerouslySetInnerHTML={{ __html: sanitizeHtml(info) }} /> || ''}
                        </p>
                        <p>
                          {infoExtended && <span className="info-extended" dangerouslySetInnerHTML={{ __html: sanitizeHtml(infoExtended) }} />}
                        </p>
                        <p className="mt-4 mb-4">
                        {Array.isArray(info2) && info2.length > 0
                          ? info2.map((word, index) => (
                              <span className="info2" key={index} style={{ marginRight: "5px" }}>{word}</span>
                            ))
                          : ''}
                        </p>
                        {resume && (
                        <span className="d-flex">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cta-btn cta-btn--resume"
                            href={"https://drive.google.com/file/d/1QHLOlQOxR4NxK-iXpooyl6pdqMW_3E73/view?usp=sharing"}
                          >
                            Resume
                          </a>
                        </span>
                      )}
                      </div>

                      {url && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn cta-btn--hero"
                          href={url}
                        >
                          LIVE
                        </a>
                      )}
                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          {`<Source Code />`}
                        </a>
                      )}

                      {title === 'Richie Billions' ? (
                        <ul data-tilt className="project-wrapper__stack-icons">
                          <li>
                            <a
                              href="https://www.facebook.com/RichieBillionsClothing"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/facebook.svg"
                                width="30"
                                height="30"
                                alt="Facebook"
                                title="Facebook"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/richiebillionsclothing"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/instagram.svg"
                                width="30"
                                height="30"
                                alt="Instagram"
                                title="Instagram"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/channel/UCd0UWbIrCNZU3Al2FN9ishQ"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/youtube.svg"
                                width="30"
                                height="30"
                                alt="YouTube"
                                title="Youtube"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                        </ul>
                      ) : (
                        ''
                      )}
                    </div>
                  </Fade>
                </Col>

                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1200}
                    distance="30px"
                    triggerOnce={true}
                  >
                    <div className="project-wrapper__image">
                      <div className="thumbnail rounded">

                        <CarouselComp
                          id={id}
                          img={img}
                          alt={alt}
                          img2={img2}
                          alt2={alt2}
                          img3={img3}
                          alt3={alt3}
                          img4={img4}
                          alt4={alt4} 
                          img5={img5}
                          alt5={alt5} 
                          img6={img6}
                          alt6={alt6} 
                          img7={img7}
                          alt7={alt7}
                          img8={img8}
                          alt8={alt8}
                        />

                      </div>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Experience;
