import React, { useState, useEffect } from 'react';
import Header from './Header/Header';
import Hero from './Hero/Hero';
import About from './About/About';
import Experience from './Experience/Experience';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
//import Footer from './Footer/Footer';
import ScrollToTop from './Scroll/scrolltoTop'
import { PortfolioProvider } from '../context/context';

import {
  headerData,
  heroData,
  aboutData,
  experienceData,
  projectsData,
  contactData,
  //footerData,
} from '../mock/data';

function App() {
  const [header, setHeader] = useState({});
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [experience, setExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  //const [footer, setFooter] = useState({});

  useEffect(() => {
    setHeader({ ...headerData });
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setExperience([ ...experienceData]);
    setProjects([ ...projectsData]);
    setContact({ ...contactData });
    //setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ header, hero, about, experience, projects, contact }}>
        <Header />
        <Hero />
        <About />
        <Experience />
        <Projects />
        <Contact />
        {/*<Footer />*/}
        <ScrollToTop />
    </PortfolioProvider>
  );
}

export default App;
