import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-awesome-reveal';
import Tilt from 'react-parallax-tilt';
import sanitizeHtml from 'sanitize-html';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const {
    paragraphOne,
    paragraphTwo,
    paragraphThree,
    resume,
  } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={700} distance="30px" triggerOnce={true}>
              <div className="about-wrapper__image">
                <div className="about__image">
                  <Tilt
                    options={{
                      reverse: false,
                      max: 25,
                      perspective: 1000,
                      scale: 1,
                      speed: 300,
                      transition: true,
                      axis: null,
                      reset: true,
                      easing: 'cubic-bezier(.03,.98,.52,.99)',
                    }}
                  >
                    <div data-tilt>
                      <div className="figure">
                        <div className="figure__image-main">
                          <img
                            alt="Rishi Singh Bhachu"
                            title="Rishi Singh Bhachu"
                            src="/avataaars-default.svg"
                            width="200"
                            height="200"
                          />
                        </div>
                        <div className="figure__image-hover">
                          <img
                            alt="Rishi Singh Bhachu"
                            title="Rishi Singh Bhachu"
                            src="/avataaars-wink.svg"
                            width="200"
                            height="200"
                          />
                        </div>
                      </div>
                    </div>
                  </Tilt>
                </div>

                <span className="about-wrapper__stack-heading">Stack &amp; Skills</span>
                <Tilt
                  options={{
                    reverse: false,
                    max: 25,
                    perspective: 1000,
                    scale: 1,
                    speed: 300,
                    transition: true,
                    axis: null,
                    reset: true,
                    easing: 'cubic-bezier(.03,.98,.52,.99)',
                  }}
                >
                  <ul data-tilt className="about-wrapper__stack-icons">

                    <li>
                      <img
                        src="/html5.svg"
                        width="30"
                        height="30"
                        alt="HTML 5"
                        title="HTML5"
                        className="rounded stack-icons"
                      />
                      <span>HTML5</span>
                    </li>
                    <li>
                      <img
                        src="/css3.svg"
                        width="30"
                        height="30"
                        alt="CSS3"
                        title="CSS3"
                        className="rounded stack-icons"
                      />
                      <span>CSS3</span>
                    </li>
                    <li>
                      <img
                        src="/sass.svg"
                        width="30"
                        height="30"
                        alt="SASS"
                        title="SASS"
                        className="rounded stack-icons"
                      />
                      <span>SASS</span>
                    </li>
                    <li>
                      <img
                        src="/javascript.svg"
                        width="30"
                        height="30"
                        alt="JavaScript"
                        title="JavaScript"
                        className="rounded stack-icons"
                      />
                      <span>JavaScript</span>
                    </li>
                    <li>
                      <img
                        src="/typescript.svg"
                        width="30"
                        height="30"
                        alt="TypeScript"
                        title="TypeScript"
                        className="rounded stack-icons"
                      />
                      <span>TypeScript</span>
                    </li>
                    <li>
                      <img
                        src="/react.svg"
                        width="30"
                        height="30"
                        alt="React.js"
                        title="React.js"
                        className="rounded stack-icons"
                      />
                      <span>React</span>
                    </li>
                    <li>
                      <img
                        src="/redux.svg"
                        width="30"
                        height="30"
                        alt="Redux"
                        title="Redux"
                        className="rounded stack-icons"
                      />
                      <span>Redux</span>
                    </li>
                    {/*
                    <li>
                      <img
                        src="/bootstrap.svg"
                        width="30"
                        height="30"
                        alt="Bootstrap"
                        title="Bootstrap"
                        className="rounded stack-icons"
                      />
                      <span>Bootstrap</span>
                    </li>
                    */}
                    <li>
                      <img
                        src="/gatsby.svg"
                        width="30"
                        height="30"
                        alt="Gatsby"
                        title="Gatsby"
                        className="rounded stack-icons"
                      />
                      <span>Gatsby</span>
                    </li>
                    {/*
                    <li>
                      <img
                        src="/wordpress.svg"
                        width="30"
                        height="30"
                        alt="WordPress"
                        title="WordPress"
                        className="rounded stack-icons"
                      />
                      <span>WordPress</span>
                    </li>
                    */}
                  {/*
                    <li>
                      <img
                        src="/spa.svg"
                        width="30"
                        height="30"
                        alt="SPA"
                        title="SPA"
                        className="rounded stack-icons"
                      />
                      <span>SPA</span>
                    </li>
                    <li>
                      <img
                        src="/pwa.svg"
                        width="30"
                        height="30"
                        alt="PWA"
                        title="PWA"
                        className="rounded stack-icons"
                      />
                      <span>PWA</span>
                    </li>
                    */}

                  </ul>

                  <ul data-tilt className="about-wrapper__stack-icons">
                    <li>
                      <img
                        src="/nodejs.svg"
                        width="30"
                        height="30"
                        alt="Node.js"
                        title="Node.js"
                        className="rounded stack-icons"
                      />
                      <span>Node</span>
                    </li>
                    <li>
                      <img
                        src="/express.svg"
                        width="30"
                        height="30"
                        alt="Express"
                        title="Express"
                        className="rounded stack-icons"
                      />
                      <span>Express</span>
                    </li>
                    <li>
                      <img
                        src="/api.svg"
                        width="30"
                        height="30"
                        alt="API"
                        title="API"
                        className="rounded stack-icons"
                      />
                      <span>API</span>
                    </li>
                    <li>
                      <img
                        src="/postgresql.svg"
                        width="30"
                        height="30"
                        alt="PostgreSQL"
                        title="PostgreSQL"
                        className="rounded stack-icons"
                      />
                      <span>PostgreSQL</span>
                    </li>
                    <li>
                      <img
                        src="/sql.svg"
                        width="30"
                        height="30"
                        alt="SQL"
                        title="SQL"
                        className="rounded stack-icons"
                      />
                      <span>SQL</span>
                    </li>

                    <li>
                      <img
                        src="/npm.svg"
                        width="30"
                        height="30"
                        alt="NPM"
                        title="NPM"
                        className="rounded stack-icons"
                      />
                      <span>NPM</span>
                    </li>

                    <li>
                      <img
                        src="/git.svg"
                        width="30"
                        height="30"
                        alt="Git"
                        title="Git"
                        className="rounded stack-icons"
                      />
                      <span>Git</span>
                    </li>
                    <li>
                      <img
                        src="/github.svg"
                        width="30"
                        height="30"
                        alt="GitHub"
                        title="GitHub"
                        className="rounded stack-icons"
                      />
                      <span>GitHub</span>
                    </li>
                    </ul>

                    <ul data-tilt className="about-wrapper__stack-icons">
                    <li>
                      <img
                        src="/tdd.svg"
                        width="30"
                        height="30"
                        alt="TDD"
                        title="TDD"
                        className="rounded stack-icons"
                      />
                      <span>TDD</span>
                    </li>
                    <li>
                      <img
                        src="/cicd.svg"
                        width="30"
                        height="30"
                        alt="CI/CD"
                        title="CI/CD"
                        className="rounded stack-icons"
                      />
                      <span>CI/CD</span>
                    </li>
                    <li>
                      <img
                        src="/jest.svg"
                        width="30"
                        height="30"
                        alt="Jest"
                        title="Jest"
                        className="rounded stack-icons"
                      />
                      <span>Jest</span>
                    </li>

                    {/*
                    <li>
                      <img
                        src="/enzyme.svg"
                        width="30"
                        height="30"
                        alt="Enzyme"
                        title="Enzyme"
                        className="rounded stack-icons"
                      />
                      <span>Enzyme</span>
                    </li>
                    */}
                    
                    <li>
                      <img
                        src="/postman.svg"
                        width="30"
                        height="30"
                        alt="Postman"
                        title="Postman"
                        className="rounded stack-icons"
                      />
                      <span>Postman</span>
                    </li>

                    <li>
                      <img
                        src="/docker.svg"
                        width="30"
                        height="30"
                        alt="Docker"
                        title="Docker"
                        className="rounded stack-icons"
                      />
                      <span>Docker</span>
                    </li>
                    <li>
                      <img
                        src="/aws.svg"
                        width="30"
                        height="30"
                        alt="AWS"
                        title="AWS"
                        className="rounded stack-icons"
                      />
                      <span>AWS</span>
                    </li>
                    {/*
                    <li>
                      <img
                        src="/netlify.svg"
                        width="30"
                        height="30"
                        alt="Netlify"
                        title="Netlify"
                        className="rounded stack-icons"
                      />
                      <span>Netlify</span>
                    </li>
                    <li>
                      <img
                        src="/heroku.svg"
                        width="30"
                        height="30"
                        alt="Heroku"
                        title="Heroku"
                        className="rounded stack-icons"
                      />
                      <span>Heroku</span>
                    </li>
                      */}
                    <li>
                      <img
                        src="/vscode.svg"
                        width="30"
                        height="30"
                        alt="VS Code"
                        title="VS Code"
                        className="rounded stack-icons"
                      />
                      <span>VS Code</span>
                    </li>

                    <li>
                      <img
                        src="/adobe.svg"
                        width="30"
                        height="30"
                        alt="Adobe CC"
                        title="Adobe CC"
                        className="rounded stack-icons"
                      />
                      <span>Adobe CC</span>
                    </li>

                  </ul>
                </Tilt>
              </div>
            </Fade>
          </Col>

          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1200} distance="30px" triggerOnce={true}>
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text italic1">
                  {<span dangerouslySetInnerHTML={{ __html: sanitizeHtml(paragraphOne) }} /> || ''}
                </p>
                <p className="about-wrapper__info-text">
                  {<span dangerouslySetInnerHTML={{ __html: sanitizeHtml(paragraphTwo) }} /> || ''}
                </p>
                <p className="about-wrapper__info-text">
                  {<span dangerouslySetInnerHTML={{ __html: sanitizeHtml(paragraphThree) }} /> || ''}
                </p>
                {resume && (
                  <span className="d-flex">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={"https://drive.google.com/file/d/1QHLOlQOxR4NxK-iXpooyl6pdqMW_3E73/view?usp=sharing"}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
