import React from 'react';
import ProjectImg from '../Image/ProjectImg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, A11y, EffectFade, Lazy } from 'swiper'; // import Swiper core and required modules

// Import Swiper styles
require('swiper/swiper.scss');
require('swiper/components/navigation/navigation.scss');
require('swiper/components/pagination/pagination.scss');
require('swiper/components/effect-fade/effect-fade.scss');
require('swiper/components/lazy/lazy.scss');

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y, EffectFade, Lazy]);

const CarouselComp = ({
    id, 
    img, 
    alt, 
    img2, 
    alt2, 
    img3, 
    alt3,  
    img4, 
    alt4, 
    img5, 
    alt5, 
    img6, 
    alt6, 
    img7, 
    alt7,
    img8, 
    alt8
}) => {

    // prefix nanoid with letters to ensure to avoid id starting with a number
    const cleanID = 'cara-' + id;

    return (

        <Swiper
            id={cleanID}
            className='mySwiper'
            spaceBetween={10}
            slidesPerView={1}
            navigation={true}
            pagination={{'clickable': true}} 
            loop={true}
            effect={'fade'}
            lazy={true}
            // onSwiper={(swiper) => console.log(swiper)} // test/debug in console
            // onSlideChange={() => console.log('slide change')} // test/debug in console
        >
            <SwiperSlide>
                <ProjectImg
                        className="d-block w-100 swiper-lazy"
                        alt={alt}
                        title={alt}
                        filename={img}
                    />    
                    <p>{alt}</p>
            </SwiperSlide>

            <SwiperSlide>
                <ProjectImg
                        className="d-block w-100 swiper-lazy"
                        alt={alt2}
                        title={alt2}
                        filename={img2}
                    />
                    <p>{alt2}</p>    
            </SwiperSlide>

            {img3 && (
            <SwiperSlide>
                <ProjectImg
                        className="d-block w-100 swiper-lazy"
                        alt={alt3}
                        title={alt3}
                        filename={img3}
                    />
                    <p>{alt3}</p>
            </SwiperSlide>
            )}
        
            {img4 && (
            <SwiperSlide>
                <ProjectImg
                        className="d-block w-100 swiper-lazy"
                        alt={alt4}
                        title={alt4}
                        filename={img4}
                    />
                    <p>{alt4}</p>
            </SwiperSlide>
            )}

            {img5 && (
            <SwiperSlide>
                <ProjectImg
                        className="d-block w-100 swiper-lazy"
                        alt={alt5}
                        title={alt5}
                        filename={img5}
                    />
                    <p>{alt5}</p>
            </SwiperSlide>
            )}

            {img6 && (
            <SwiperSlide>
                <ProjectImg
                        className="d-block w-100 swiper-lazy"
                        alt={alt6}
                        title={alt6}
                        filename={img6}
                    />
                    <p>{alt6}</p>
            </SwiperSlide>
            )}

            {img7 && (
            <SwiperSlide>
                <ProjectImg
                        className="d-block w-100 swiper-lazy"
                        alt={alt7}
                        title={alt7}
                        filename={img7}
                    />
                    <p>{alt7}</p>
            </SwiperSlide>
            )}

            {img8 && (
            <SwiperSlide>
                <ProjectImg
                        className="d-block w-100 swiper-lazy"
                        alt={alt8}
                        title={alt8}
                        filename={img8}
                    />
                    <p>{alt8}</p>
            </SwiperSlide>
            )}

        </Swiper>

    )

}

export default CarouselComp;